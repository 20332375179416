import React, { useEffect, useRef, useState } from "react";
import {
  Image,
  Button,
  Nav,
  Row,
  Col,
  Tab,
  Form,
  InputGroup,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import isEqual from "lodash/isEqual";
import {
  countriesListStart,
  mobileCodeListStart,
  nationalitiesListStart,
  updateAddressStart,
  updateOnboardingDetailsStart,
} from "../../store/slices/AdminSlice";
import Select, { components } from "react-select";
import { countries } from "countries-list";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import { ButtonLoader } from "../Helper/Loader";
import DatePicker, { DateObject } from "react-multi-date-picker";
import iso from "iso-3166-1";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
  getCountries,
} from "react-phone-number-input";

const BusinessAccountDetails = (props) => {

  const formRef = useRef();
  const dispatch = useDispatch();
  const t = useTranslation("settings.setting");

  const profile = useSelector((state) => state.admin.profile);
  const updateOnboardingDetails = useSelector((state) => state.admin.updateOnboardingDetails);
  const nationalitiesList = useSelector((state) => state.admin.nationalitiesList);
  const countriesList = useSelector((state) => state.admin.countriesList);
  const mobileCodeList = useSelector((state) => state.admin.mobileCodeList);

  const [skipRender, setSkipRender] = useState(true);
  const [deliverySameAsBilling, setDeliverySameAsBilling] = useState(false)
  const [close, setClose] = useState(false);
  const [filteredCountries, setFilteredCountries] = useState([])
  const [countryOptions, setCountryOptions] = useState([])
  const [nationalitiesOptions, setNationalitiesOptions] = useState([])

  const title_options = [
    { value: "Mr", label: "Mr" },
    { value: "Miss", label: "Miss" },
    { value: "Mrs", label: "Mrs" },
  ];

  const today = new Date();
  const maxDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  maxDate.setDate(maxDate.getDate() - 1);
  maxDate.setHours(23, 59, 59, 999);

  const customFilterOption = (option, inputValue) => {
    return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
  };

  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("title.required")),
    first_name: Yup.string()
      .required(t("name.required"))
      .matches(/^\S.*$/, t("name.required"))
      .matches(/^[A-Za-z\s]+$/, t("name.invalid"))
      .min(3, t("name.min_length_invalid"))
      .trim(),
    middle_name: Yup.string()
      .matches(/^\S.*$/, t("name.required"))
      .matches(/^[A-Za-z\s]+$/, t("name.invalid"))
      .min(3, t("name.min_length_invalid"))
      .trim(),
    last_name: Yup.string()
      .required(t("name.required"))
      .matches(/^[A-Za-z\s]+$/, t("name.invalid"))
      .min(1, t("last_name.min_length_invalid"))
      .trim(),
    email: Yup.string()
      .email(t("email.invalid"))
      .required(t("email.required"))
      .min(12, t("email.min_length_invalid"))
      .max(255, t("email.max_length_invalid")),
    dob: Yup.string().required(t("required")),
    mobile: Yup.string()
      .required(t("mobile.required"))
      .matches(/^(?=.*[0-9])/, t("mobile.invalid"))
      .min(10, t("mobile.min_length_invalid"))
      .max(15, t("mobile.max_length_invalid")),
    gender: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("required"))
      .trim(),
    nationality: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("required"))
      .trim(),
    operational_primary_address: Yup.string()
      .required(t("required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        t("operational_address_1.invalid")
      )
      .min(5, t("operational_address_1.min_length_invalid"))
      .trim(),
    operational_secondary_address: Yup.string()
      .required(t("required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        t("operational_address_2.invalid")
      )
      .min(5, t("operational_address_2.min_length_invalid"))
      .trim(),
    operational_city: Yup.string()
      .required(t("required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        t("operational_city.invalid")
      )
      .min(3, t("operational_city.min_length_invalid"))
      .trim(),
    operational_state: Yup.string()
      .required(t("required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        t("operational_state.invalid")
      )
      .min(2, t("operational_state.min_length_invalid"))
      .trim(),
    operational_zipcode: Yup.string()
      .required(t("required"))
      .matches(/^[A-Za-z0-9]+$/, t("operational_pincode.invalid"))
      .min(4, t("operational_state.min_length_invalid")),
    operational_country: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("operational_country.invalid"))
      .trim(),
    foundation_primary_address: Yup.string()
      .required(t("required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        t("registered_address_1.invalid")
      )
      .min(5, t("registered_address_1.min_length_invalid"))
      .trim(),
    foundation_secondary_address: Yup.string()
      .required(t("required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        t("operational_address_2.invalid")
      )
      .min(5, t("operational_address_2.min_length_invalid"))
      .trim(),
    foundation_city: Yup.string()
      .required(t("required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        t("operational_city.invalid")
      )
      .min(3, t("registered_city.min_length_invalid"))
      .trim(),
    foundation_state: Yup.string()
      .required(t("required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        t("registered_state.invalid")
      )
      .min(2, t("registered_state.min_length_invalid"))
      .trim(),
    foundation_zipcode: Yup.string()
      .required(t("required"))
      .matches(/^[A-Za-z0-9]+$/, t("registered_pincode.invalid"))
      .min(4, t("registered_pincode.min_length_invalid")),
    foundation_country: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("registered_country.invalid"))
      .trim(),
    tax_id_type: Yup.string().required(t("required")),
    // tax_id_number: Yup.string().required(t("required")),
    // 
    tax_id_number: Yup.string().required(t("required")).trim().notOneOf([""], t("required")),
    tax_id_issuance_country: Yup.string().required(t("required")),
    foundation_date: Yup.string().required(t("required")),
    tax_id_date_issued: Yup.string().required(t("required")),
    tax_id_date_expiry: Yup.string().required(t("required")),
    purpose_of_account_opening: Yup.string().required(t("required")),
    source_of_funds: Yup.string().required(t("required")),
    // additional_details: Yup.string().required(t("required")),
    company_name: Yup.string()
      .required(t("required"))
      .matches(/^[a-zA-Z\s]+$/, t("company_name.invalid"))
      .min(1, t("company_name.minimum_characters")),
    preferred_name: Yup.string()
      .required(t("required"))
      .matches(/^[a-zA-Z\s]+$/, t("preferred_name.invalid"))
      .min(1, t("required_note")),
  });

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#0194ff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#0194ff" : "#fff",
      color: state.isFocused || state.isSelected ? "#fff" : "#000",
      ":hover": {
        backgroundColor: "#0194ff",
        color: "#fff",
      },
    }),
  };

  const taxIdOptions = [
    { label: "EIN", value: "ein" },
    { label: "TIN", value: "tin" },
    { label: "UEN", value: "uen" },
    { label: "ITN", value: "itn" },
    { label: "BRN", value: "brn" },
    { label: "NPWP", value: "npwp" },
    { label: "IN", value: "in" },
  ];

  const transactionOptions = [
    { label: "Cash Deposit", value: "cash_deposit" },
    { label: "Cash Withdrawal", value: "cash_withdrawal" },
    { label: "Forex", value: "forex" },
    { label: "Loan Payments", value: "loan_payments" },
    { label: "Online Payment", value: "online_payment" },
    { label: "Others", value: "others" },
    { label: "Wire Transfers", value: "wire_transfers" },
  ];

  const fundingOptions = [
    { label: "Debt", value: "debt" },
    { label: "Debt Capital", value: "debt_capital" },
    { label: "Directors' Capital", value: "directors_capital" },
    { label: "Equity Capital", value: "equity_capital" },
    { label: "Others", value: "others" },
    { label: "Term Loan", value: "term_loan" },
    { label: "Venture Funding", value: "venture_funding" },
  ];

  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile.invalid"));
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile.invalid"));
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("mobile.required"));
      return false;
    }
  };

  const handleSubmit = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");

      // const invalidCodes = [
      //   "93", // Afghanistan (AF)
      //   "375", // Belarus (BY)
      //   "236", // Central African Republic (CF)
      //   "86", // China (CN)
      //   "53", // Cuba (CU)
      //   "243", // Democratic Republic of the Congo (CD)
      //   "251", // Ethiopia (ET)
      //   "91", // India (IN)
      //   "98", // Iran (IR)
      //   "964", // Iraq (IQ)
      //   "218", // Libya (LY)
      //   "223", // Mali (ML)
      //   "505", // Nicaragua (NI)
      //   "850", // North Korea (KP)
      //   "252", // Somalia (SO)
      //   "211", // South Sudan (SS)
      //   "249", // Sudan (SD)
      //   "963", // Syria (SY)
      //   "1", // United States (US)
      //   "58", // Venezuela (VE)
      //   "967", // Yemen (YE)
      //   "263", // Zimbabwe (ZW)
      // ];

      // if (invalidCodes.includes(countryCode)) {
      //   formRef.current.setFieldError("mobile", t("mobile.invalid"));
      //   return;
      // }

      dispatch(
        updateOnboardingDetailsStart({
          ...values,
          dob: values.dob.split("-").reverse().join("-"),
          mobile: mobile,
          mobile_country_code: countryCode,
          foundation_date: values.foundation_date
            .split("-")
            .reverse()
            .join("-"),
          tax_id_date_issued: values.tax_id_date_issued
            .split("-")
            .reverse()
            .join("-"),
          tax_id_date_expiry: values.tax_id_date_expiry
            .split("-")
            .reverse()
            .join("-"),
        })
      );
    }
  };

  const handleCheckboxChange = () => {
    setDeliverySameAsBilling(!deliverySameAsBilling);
    if (!deliverySameAsBilling) {
      // Copy values from billing address to delivery address fields
      const billingAddress = formRef.current.values;
      formRef.current.setValues({
        ...billingAddress,
        foundation_primary_address: billingAddress.operational_primary_address,
        foundation_secondary_address:
          billingAddress.operational_secondary_address,
        foundation_country: billingAddress.operational_country,
        foundation_state: billingAddress.operational_state,
        foundation_city: billingAddress.operational_city,
        foundation_zipcode: billingAddress.operational_zipcode,
      });
    }
  };

  const handleAddressChanges = () => {
    const billingValues = formRef.current.values;
    const deliveryValues = {
      foundation_primary_address: billingValues.operational_primary_address,
      foundation_secondary_address: billingValues.operational_secondary_address,
      foundation_country: billingValues.operational_country,
      foundation_state: billingValues.operational_state,
      foundation_city: billingValues.operational_city,
      foundation_zipcode: billingValues.operational_zipcode,
    };
    const addressesAreEqual = isEqual(billingValues, deliveryValues);
    setDeliverySameAsBilling(addressesAreEqual);
  };

  useEffect(() => {
    if(!profile.loading && Object.keys(profile.data).length > 0){
      const addressValues = formRef.current.values;
      const operationalValues = {
        primary_address: addressValues.operational_primary_address,
        secondary_address: addressValues.operational_secondary_address,
        country: addressValues.operational_country,
        state: addressValues.operational_state,
        city: addressValues.operational_city,
        zipcode: addressValues.operational_zipcode,
      };
      const foundationValues = {
        primary_address: addressValues.foundation_primary_address,
        secondary_address: addressValues.foundation_secondary_address,
        country: addressValues.foundation_country,
        state: addressValues.foundation_state,
        city: addressValues.foundation_city,
        zipcode: addressValues.foundation_zipcode,
      };
      if(isEqual(operationalValues, foundationValues)){
        setDeliverySameAsBilling(true)
      }
    }
  }, [profile]);

  useEffect(()=> {
    dispatch(mobileCodeListStart())
    dispatch(nationalitiesListStart({ all: 1 }));
    dispatch(countriesListStart({ all: 1 }))
  }, []);

  useEffect(()=> {
    if(!skipRender && !mobileCodeList.buttonDisable && Object.keys(mobileCodeList.data).length > 0){
      if(mobileCodeList.data.mobile_country_codes.length > 0){
        setFilteredCountries(mobileCodeList.data.mobile_country_codes)
      }
    }
    setSkipRender(false);
  }, [mobileCodeList])

  useEffect(()=> {
    if(!skipRender && !nationalitiesList.buttonDisable && Object.keys(nationalitiesList.data).length > 0){
      if(nationalitiesList.data.nationalities.length > 0){
        setNationalitiesOptions(nationalitiesList.data.nationalities.map((item)=> ({
          value: item.alpha_2_code,
          label: item.country_name,
        })))
      }
    }
    setSkipRender(false);
  }, [nationalitiesList]);

  useEffect(()=> {
    if(!skipRender && !countriesList.buttonDisable && Object.keys(countriesList.data).length > 0){
      if(countriesList.data.countries.length > 0){
        setCountryOptions(countriesList.data.countries.map((item)=> ({
          value: item.alpha_2_code,
          label: item.country_name,
        })))
      }
    }
    setSkipRender(false);
  }, [countriesList])

  return (
    <div className="efi-tab-featur-card">
      <div className="efi-bank-billing-address-frame mb-3">
        {profile.data.user_business.operational_address && profile.data.user_business.foundation_address ? (
          <div className="efi-bank-billing-address-fields">
            <Formik
              initialValues={{
                title: profile.data.title,
                first_name: profile.data.first_name,
                middle_name: profile.data.middle_name || "",
                last_name: profile.data.last_name,
                email: profile.data.email,
                dob: profile.data.dob.split("-").reverse().join("-") || "",
                gender: profile.data.gender,
                nationality: profile.data.nationality,
                mobile:
                  `+${
                    profile.data.mobile_country_code + profile.data.mobile
                  }` || "",
                operational_primary_address:
                  profile.data.user_business.operational_address
                    ?.primary_address,
                operational_secondary_address:
                  profile.data.user_business.operational_address
                    ?.secondary_address,
                operational_country:
                  profile.data.user_business.operational_address?.country,
                operational_state:
                  profile.data.user_business.operational_address?.state,
                operational_city:
                  profile.data.user_business.operational_address?.city,
                operational_zipcode:
                  profile.data.user_business.operational_address?.zipcode,
                foundation_primary_address:
                  profile.data.user_business.foundation_address
                    ?.primary_address,
                foundation_secondary_address:
                  profile.data.user_business.foundation_address
                    ?.secondary_address,
                foundation_country:
                  profile.data.user_business.foundation_address?.country,
                foundation_state:
                  profile.data.user_business.foundation_address?.state,
                foundation_city:
                  profile.data.user_business.foundation_address?.city,
                foundation_zipcode:
                  profile.data.user_business.foundation_address?.zipcode,
                tax_id_type: profile.data.user_business.tax_id_type,
                tax_id_number: profile.data.user_business.tax_id_number,
                tax_id_issuance_country:
                  profile.data.user_business.tax_id_issuance_country,
                foundation_date: profile.data.user_business.foundation_date
                  .split("-")
                  .reverse()
                  .join("-"),
                tax_id_date_issued:
                  profile.data.user_business.tax_id_date_issued
                    .split("-")
                    .reverse()
                    .join("-"),
                tax_id_date_expiry:
                  profile.data.user_business.tax_id_date_expiry
                    .split("-")
                    .reverse()
                    .join("-"),
                purpose_of_account_opening:
                  profile.data.user_business.purpose_of_account_opening,
                source_of_funds: profile.data.user_business.source_of_funds,
                additional_details:
                  profile.data.user_business.additional_details,
                company_name: profile.data.user_business.company_name,
                preferred_name: profile.data.user_business.preferred_name,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              innerRef={formRef}
            >
              {({ setFieldValue, values, touched, errors }) => (
                <FORM
                  className="efi-bank-default-form efi-onboarding-form-after-login w-100"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1em",
                  }}
                >
                  <Row>
                    <div className="onboarding-auth-header">
                      <h5>{t("personal_information")}</h5>
                    </div>
                    <Col md={6}>
                      <div className="xs-marg-btm">
                        <Row className="gx-1">
                          <Col lg={3} className="resp-mrg-btm-xs">
                            <div className="efi-bank-auth-input-group">
                              <Form.Label className="default-form-label w-100">
                                {t("title.label")} <span>*</span>
                              </Form.Label>
                              <Select
                                styles={customStyles}
                                value={title_options.find(
                                  (item) => (item.value = values.title)
                                )}
                                onChange={(option) => {
                                  setFieldValue("title", option.value);
                                }}
                                className={`${
                                  errors.title && touched.title
                                    ? "input-group-error css-1l8uzyq-container"
                                    : ""
                                }`}
                                options={title_options}
                                name="title"
                                placeholder={<>Title</>}
                                isSearchable={false}
                              />
                              <ErrorMessage
                                component={"div"}
                                name="title"
                                className="errorMsg"
                              />
                            </div>
                          </Col>
                          <Col lg={9}>
                            <div className="efi-bank-auth-input-group">
                              <Form.Label className="default-form-label w-100">
                                {t("first_name")} <span>*</span>
                              </Form.Label>
                              <InputGroup
                                className={`${
                                  errors.first_name && touched.first_name
                                    ? "input-group-error name"
                                    : ""
                                }`}
                              >
                                <InputGroup.Text>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="#c3c7ca"
                                      d="M12 12a5 5 0 100-10 5 5 0 000 10z"
                                    ></path>
                                    <path
                                      fill="#171717"
                                      d="M12 14.5c-5.01 0-9.09 3.36-9.09 7.5 0 .28.22.5.5.5h17.18c.28 0 .5-.22.5-.5 0-4.14-4.08-7.5-9.09-7.5z"
                                    ></path>
                                  </svg>
                                </InputGroup.Text>
                                <Field
                                  className="form-control"
                                  name="first_name"
                                  type="text"
                                  placeholder="First Name"
                                  aria-label="text"
                                  aria-describedby="text-addon"
                                  maxLength={16}
                                />
                              </InputGroup>
                              <ErrorMessage
                                component={"div"}
                                name="first_name"
                                className="errorMsg"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="xs-marg-btm efi-bank-auth-input-group">
                        <Row className="g-1">
                          <Col lg={6} className="resp-mrg-btm-xs name">
                            <Form.Label className="default-form-label w-100">
                              {t("middle_name")}
                            </Form.Label>
                            <InputGroup
                              className={`${
                                errors.middle_name && touched.middle_name
                                  ? "input-group-error name"
                                  : ""
                              }`}
                            >
                              <InputGroup.Text>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="#c3c7ca"
                                    d="M12 12a5 5 0 100-10 5 5 0 000 10z"
                                  ></path>
                                  <path
                                    fill="#171717"
                                    d="M12 14.5c-5.01 0-9.09 3.36-9.09 7.5 0 .28.22.5.5.5h17.18c.28 0 .5-.22.5-.5 0-4.14-4.08-7.5-9.09-7.5z"
                                  ></path>
                                </svg>
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                name="middle_name"
                                type="text"
                                placeholder="Middle Name"
                                aria-label="text"
                                aria-describedby="text-addon"
                                maxLength={16}
                              />
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="middle_name"
                              className="errorMsg"
                            />
                          </Col>
                          <Col lg={6}>
                            <div className="efi-bank-auth-input-group">
                              <Form.Label className="default-form-label w-100">
                                {t("last_name.label")} <span>*</span>
                              </Form.Label>
                              <InputGroup
                                className={`${
                                  errors.last_name && touched.last_name
                                    ? "input-group-error name"
                                    : ""
                                }`}
                              >
                                <InputGroup.Text>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="#c3c7ca"
                                      d="M12 12a5 5 0 100-10 5 5 0 000 10z"
                                    ></path>
                                    <path
                                      fill="#171717"
                                      d="M12 14.5c-5.01 0-9.09 3.36-9.09 7.5 0 .28.22.5.5.5h17.18c.28 0 .5-.22.5-.5 0-4.14-4.08-7.5-9.09-7.5z"
                                    ></path>
                                  </svg>
                                </InputGroup.Text>
                                <Field
                                  className="form-control"
                                  name="last_name"
                                  type="text"
                                  placeholder="Last Name"
                                  aria-label="text"
                                  aria-describedby="text-addon"
                                  maxLength={16}
                                />
                              </InputGroup>
                              <ErrorMessage
                                component={"div"}
                                name="last_name"
                                className="errorMsg"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="mb-3 efi-bank-auth-input-group">
                        <Form.Label className="default-form-label w-100">
                          {t("email")} <span>*</span>
                          {profile.data.onboarding >= 4 ? <span> This value can't be changed</span> : null}
                        </Form.Label>
                        <InputGroup
                          className={`${
                            errors.email && touched.email
                              ? "input-group-error"
                              : ""
                          }`}
                        >
                          <InputGroup.Text>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#c3c7ca"
                                d="M17 20.5H7c-3 0-5-1.5-5-5v-7c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v7c0 3.5-2 5-5 5z"
                              ></path>
                              <path
                                fill="#171717"
                                d="M12 12.87c-.84 0-1.69-.26-2.34-.79l-3.13-2.5a.748.748 0 01.93-1.17l3.13 2.5c.76.61 2.05.61 2.81 0l3.13-2.5c.32-.26.8-.21 1.05.12.26.32.21.8-.12 1.05l-3.13 2.5c-.64.53-1.49.79-2.33.79z"
                              ></path>
                            </svg>
                          </InputGroup.Text>
                          <Field
                            className="form-control"
                            name="email"
                            type="email"
                            placeholder="Email"
                            aria-label="Email"
                            aria-describedby="email-addon"
                            disabled={profile.data.onboarding >= 4}
                          />
                        </InputGroup>
                        <ErrorMessage
                          component={"div"}
                          name="email"
                          className="errorMsg"
                        />
                      </div>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label w-100">
                          {t("gender")} <span>*</span>
                        </Form.Label>
                        <div className="gender-check-box">
                          <div className="pretty p-default p-round">
                            <input
                              type="radio"
                              name="flexRadioDefault"
                              id="male"
                              checked={values.gender == "M"}
                              onChange={() => setFieldValue("gender", "M")}
                            />
                            <div className="state p-primary-o">
                              <label> {t("male")}</label>
                            </div>
                          </div>

                          <div className="pretty p-default p-round">
                            <input
                              type="radio"
                              name="flexRadioDefault"
                              id="female"
                              checked={values.gender == "F"}
                              onChange={() => setFieldValue("gender", "F")}
                            />
                            <div className="state p-primary-o">
                              <label> {t("female")}</label>
                            </div>
                          </div>
                          <div className="pretty p-default p-round">
                            <input
                              type="radio"
                              name="flexRadioDefault"
                              id="other"
                              checked={values.gender == "O"}
                              onChange={() => setFieldValue("gender", "O")}
                            />
                            <div className="state p-primary-o">
                              <label> {t("others")}</label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="gender"
                          className="errorMsg"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3 efi-bank-auth-input-group">
                        <Form.Label className="default-form-label w-100">
                          {t("mobile.label")} <span>*</span>
                          {profile.data.onboarding >= 4 ? <span> This value can't be changed</span> : null}
                        </Form.Label>
                        <InputGroup
                          className={`personal-information-form form-control`}
                        >
                          <PhoneInput
                            name="mobile"
                            value={values.mobile}
                            placeholder={t("mobile.placeholder")}
                            onChange={(value) => {
                              setFieldValue("mobile", value);
                            }}
                            // onBlur={() => formRef.current.submitForm()}
                            international
                            countries={mobileCodeList?.data?.mobile_country_codes || []}
                            className={`${
                              touched.mobile && errors.mobile
                                ? "is-invalid"
                                : ""
                            }`}
                            // maxLength={17}
                            disabled={profile.data.onboarding >= 4}
                          />
                        </InputGroup>
                        <ErrorMessage
                          component={"div"}
                          name="mobile"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label w-100">
                          {t("dob.name")} <span>*</span>
                        </Form.Label>
                        <div className="single-date-picker">
                          <InputGroup className="mb-0">
                            <DatePicker
                              minDate={new Date("1900-01-01")}
                              maxDate={maxDate}
                              value={values.dob}
                              onChange={(e) => {
                                setFieldValue(
                                  "dob",
                                  e
                                    ? `${e.day < 10 ? "0" + e.day : e.day}-${
                                        e.month < 10 ? "0" + e.month : e.month
                                      }-${e.year}`
                                    : ""
                                );
                                setClose(true);
                              }}
                              onOpen={() =>
                                setFieldValue(
                                  "dob",
                                  values.dob
                                    ? values.dob
                                    : new Date(maxDate)
                                        .toISOString()
                                        .split("T")[0]
                                        .toString()
                                        .split("-")
                                        .reverse()
                                        .join("-")
                                )
                              }
                              placeholder={t("dob.placeholder")}
                              width="100%"
                              format="DD-MM-YYYY"
                              id="date-picker-input-111"
                              editable={false}
                              onOpenPickNewDate={false}
                              onFocus={(e) => e.target.blur()}
                              className="hide-caret"
                            ></DatePicker>

                            <InputGroup.Text
                              onClick={() => {
                                const input =
                                  document.getElementById("date-picker-input-111");
                                if (input) {
                                  input.focus();
                                }
                              }}
                            >
                              {close ? (
                                <>
                                  <button
                                    type="button"
                                    className="search-btn date-cancel"
                                    onClick={() => {
                                      setFieldValue("dob", "");
                                      setClose(false);
                                    }}
                                  >
                                    <svg
                                      height="20"
                                      width="20"
                                      viewBox="0 0 20 20"
                                      aria-hidden="true"
                                      focusable="false"
                                      class="css-tj5bde-Svg"
                                    >
                                      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                    </svg>
                                  </button>
                                </>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  fill="none"
                                  viewBox="0 0 18 18"
                                >
                                  <g clipPath="url(#clip0_47_6)">
                                    <path
                                      fill="#8F9899"
                                      d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_47_6">
                                      <path
                                        fill="#fff"
                                        d="M0 0H18V18H0z"
                                      ></path>
                                    </clipPath>
                                  </defs>
                                </svg>
                              )}
                            </InputGroup.Text>
                          </InputGroup>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="dob"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label w-100">
                          {t("nationality.name")} <span>*</span>
                        </Form.Label>
                        <Select
                          options={nationalitiesOptions}
                          styles={customStyles}
                          filterOption={customFilterOption}
                          classNamePrefix="react-select"
                          onChange={(selectedOption) =>
                            setFieldValue("nationality", selectedOption.value)
                          }
                          value={nationalitiesOptions.find(
                            (option) => option.value === values.nationality
                          )}
                          placeholder={t("nationality.placeholder")}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="nationality"
                          className="errorMsg"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <div className="onboarding-auth-header">
                      <h5>{t("business_information")}</h5>
                    </div>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("company_name.name")} <span>*</span>
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder={t("company_name.placeholder")}
                          aria-describedby="text-addon"
                          name="company_name"
                          onInput={handleAddressChanges}
                        />
                        <ErrorMessage
                          name="company_name"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label w-100">
                          {t("tax_id_type.name")} <span>*</span>
                        </Form.Label>
                        <Select
                          options={taxIdOptions}
                          styles={customStyles}
                          classNamePrefix="react-select"
                          onChange={(selectedOption) =>
                            setFieldValue("tax_id_type", selectedOption.value)
                          }
                          value={taxIdOptions.find(
                            (option) => option.value === values.tax_id_type
                          )}
                          placeholder={t("tax_id_type.placeholder")}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="tax_id_type"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label w-100">
                          {t("registered_date.name")} <span>*</span>
                        </Form.Label>
                        <div className="single-date-picker">
                          <InputGroup className="mb-0">
                            <DatePicker
                              minDate={new Date("1900-01-01")}
                              maxDate={new Date()}
                              value={values.foundation_date}
                              onChange={(e) =>
                                setFieldValue(
                                  "foundation_date",
                                  e
                                    ? `${e.day < 10 ? "0" + e.day : e.day}-${
                                        e.month < 10 ? "0" + e.month : e.month
                                      }-${e.year}`
                                    : ""
                                )
                              }
                              placeholder={t("registered_date.placeholder")}
                              width="100%"
                              format="DD-MM-YYYY"
                              id="date-picker-input-10"
                            />
                            <InputGroup.Text
                              onClick={() => {
                                const input =
                                  document.getElementById("date-picker-input-10");
                                if (input) {
                                  input.focus();
                                }
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                fill="none"
                                viewBox="0 0 18 18"
                              >
                                <g clipPath="url(#clip0_47_6)">
                                  <path
                                    fill="#8F9899"
                                    d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_47_6">
                                    <path fill="#fff" d="M0 0H18V18H0z"></path>
                                  </clipPath>
                                </defs>
                              </svg>
                            </InputGroup.Text>
                          </InputGroup>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="foundation_date"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label w-100">
                          {t("tax_id_date_issued.name")} <span>*</span>
                        </Form.Label>
                        <div className="single-date-picker">
                          <InputGroup className="mb-0">
                            <DatePicker
                              minDate={new Date("1900-01-01")}
                              maxDate={new Date()}
                              value={values.tax_id_date_issued}
                              onChange={(e) =>
                                setFieldValue(
                                  "tax_id_date_issued",
                                  e
                                    ? `${e.day < 10 ? "0" + e.day : e.day}-${
                                        e.month < 10 ? "0" + e.month : e.month
                                      }-${e.year}`
                                    : ""
                                )
                              }
                              placeholder={t("tax_id_date_issued.placeholder")}
                              width="100%"
                              format="DD-MM-YYYY"
                              id="date-picker-input-1"
                            />
                            <InputGroup.Text
                              onClick={() => {
                                const input = document.getElementById(
                                  "date-picker-input-1"
                                );
                                if (input) {
                                  input.focus();
                                }
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                fill="none"
                                viewBox="0 0 18 18"
                              >
                                <g clipPath="url(#clip0_47_6)">
                                  <path
                                    fill="#8F9899"
                                    d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_47_6">
                                    <path fill="#fff" d="M0 0H18V18H0z"></path>
                                  </clipPath>
                                </defs>
                              </svg>
                            </InputGroup.Text>
                          </InputGroup>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="tax_id_date_issued"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label w-100">
                          {t("purpose_of_account_opening.name")} <span>*</span>
                        </Form.Label>
                        <Select
                          options={transactionOptions}
                          styles={customStyles}
                          classNamePrefix="react-select"
                          onChange={(selectedOption) =>
                            setFieldValue(
                              "purpose_of_account_opening",
                              selectedOption.value
                            )
                          }
                          value={transactionOptions.find(
                            (option) =>
                              option.value === values.purpose_of_account_opening
                          )}
                          placeholder={t(
                            "purpose_of_account_opening.placeholder"
                          )}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="purpose_of_account_opening"
                          className="errorMsg"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("preferred_name.name")} &nbsp;
                          <span>This value can't be changed</span>
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder={t("preferred_name.placeholder")}
                          aria-describedby="text-addon"
                          name="preferred_name"
                          onInput={handleAddressChanges}
                          disabled
                        />
                        <ErrorMessage
                          name="preferred_name"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("tax_id_number.name")} <span>*</span>
                        </Form.Label>
                        <Field
                          className="form-control"
                          type={values.tax_id_type == "tin" ? "text" : "number"}
                          placeholder={t("tax_id_number.placeholder")}
                          aria-describedby="text-addon"
                          name="tax_id_number"
                        />
                        <ErrorMessage
                          name="tax_id_number"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label w-100">
                          {t("tax_id_issuance_country.name")} <span>*</span>
                        </Form.Label>
                        <Select
                          options={nationalitiesOptions}
                          styles={customStyles}
                          classNamePrefix="react-select"
                          onChange={(selectedOption) =>
                            setFieldValue(
                              "tax_id_issuance_country",
                              // iso.whereAlpha2(selectedOption.value).alpha3
                              selectedOption.value
                            )
                          }
                          value={nationalitiesOptions.find((option) =>
                            option.value === values.tax_id_issuance_country
                              ? values.tax_id_issuance_country
                              : ""
                          )}
                          placeholder={t("tax_id_issuance_country.placeholder")}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="tax_id_issuance_country"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label w-100">
                          {t("tax_id_date_expiry.name")} <span>*</span>
                        </Form.Label>
                        <div className="single-date-picker">
                          <InputGroup className="mb-0">
                            <DatePicker
                              minDate={new Date()}
                              // maxDate={new Date()}
                              value={values.tax_id_date_expiry}
                              onChange={(e) =>
                                setFieldValue(
                                  "tax_id_date_expiry",
                                  e
                                    ? `${e.day < 10 ? "0" + e.day : e.day}-${
                                        e.month < 10 ? "0" + e.month : e.month
                                      }-${e.year}`
                                    : ""
                                )
                              }
                              placeholder={t("tax_id_date_expiry.placeholder")}
                              width="100%"
                              format="DD-MM-YYYY"
                              id="date-picker-input-2"
                            />
                            <InputGroup.Text
                              onClick={() => {
                                const input = document.getElementById(
                                  "date-picker-input-2"
                                );
                                if (input) {
                                  input.focus();
                                }
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                fill="none"
                                viewBox="0 0 18 18"
                              >
                                <g clipPath="url(#clip0_47_6)">
                                  <path
                                    fill="#8F9899"
                                    d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_47_6">
                                    <path fill="#fff" d="M0 0H18V18H0z"></path>
                                  </clipPath>
                                </defs>
                              </svg>
                            </InputGroup.Text>
                          </InputGroup>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="tax_id_date_expiry"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label w-100">
                          {t("source_of_funds.name")} <span>*</span>
                        </Form.Label>
                        <Select
                          options={fundingOptions}
                          styles={customStyles}
                          classNamePrefix="react-select"
                          onChange={(selectedOption) =>
                            setFieldValue(
                              "source_of_funds",
                              selectedOption.value
                            )
                          }
                          value={fundingOptions.find(
                            (option) => option.value === values.source_of_funds
                          )}
                          placeholder={t("source_of_funds.placeholder")}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="source_of_funds"
                          className="errorMsg"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={6}>
                      <div className="onboarding-auth-header">
                        <h5>{t("operational_address")}</h5>
                      </div>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("operational_address_1.name")} <span>*</span>
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder={t("operational_address_1.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="operational_primary_address"
                          onInput={handleAddressChanges}
                        />
                        <ErrorMessage
                          name="operational_primary_address"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("operational_address_2.name")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder={t("operational_address_2.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="operational_secondary_address"
                          onInput={handleAddressChanges}
                        />
                        <ErrorMessage
                          name="operational_secondary_address"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("operational_country.name")} <span>*</span>
                        </Form.Label>
                        <Select
                          options={countryOptions}
                          styles={customStyles}
                          classNamePrefix="react-select"
                          onChange={(selectedOption) => {
                            setFieldValue(
                              "operational_country",
                              selectedOption.value
                            );
                            handleAddressChanges();
                          }}
                          value={countryOptions.find(
                            (option) =>
                              option.value === values.operational_country
                          )}
                          placeholder={t("operational_country.placeholder")}
                        />
                        <ErrorMessage
                          name="operational_country"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("operational_state.name")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder={t("operational_state.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="operational_state"
                          onInput={handleAddressChanges}
                        />
                        <ErrorMessage
                          name="operational_state"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("operational_city.name")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder={t("operational_city.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="operational_city"
                          onInput={handleAddressChanges}
                        />
                        <ErrorMessage
                          name="operational_city"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("operational_pincode.name")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder={t("operational_pincode.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="operational_zipcode"
                          onInput={handleAddressChanges}
                        />
                        <ErrorMessage
                          name="operational_zipcode"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <div className="onboarding-auth-header d-flex align-items-center justify-content-between">
                        <h5>{t("foundation_address")}</h5>
                        <Checkbox
                          checked={deliverySameAsBilling}
                          disabled={deliverySameAsBilling}
                          onChange={handleCheckboxChange}
                          icon={
                            <Image
                              src={
                                window.location.origin + "/img/tick-mark.svg"
                              }
                              className="tick-mark-checkbox"
                            />
                          }
                        >
                          {t("same_as_operational_addres")}
                        </Checkbox>
                      </div>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("registered_address_1.name")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder={t("registered_address_1.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="foundation_primary_address"
                          onInput={handleAddressChanges}
                        />
                        <ErrorMessage
                          name="foundation_primary_address"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("registered_address_2.name")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder={t("registered_address_2.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="foundation_secondary_address"
                          onInput={handleAddressChanges}
                        />
                        <ErrorMessage
                          name="foundation_secondary_address"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("registered_country.name")} <span>*</span>
                        </Form.Label>
                        <Select
                          options={countryOptions}
                          styles={customStyles}
                          classNamePrefix="react-select"
                          onChange={(selectedOption) => {
                            setFieldValue(
                              "foundation_country",
                              selectedOption.value
                            );
                            handleAddressChanges();
                          }}
                          value={countryOptions.find(
                            (option) =>
                              option.value === values.foundation_country
                          )}
                          placeholder={t("registered_country.placeholder")}
                        />
                        <ErrorMessage
                          name="foundation_country"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("registered_state.name")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder={t("registered_state.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="foundation_state"
                          onInput={handleAddressChanges}
                        />
                        <ErrorMessage
                          name="foundation_state"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("registered_city.name")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder={t("registered_city.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="foundation_city"
                          onInput={handleAddressChanges}
                        />
                        <ErrorMessage
                          name="foundation_city"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("registered_pincode.name")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder={t("registered_pincode.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="foundation_zipcode"
                          onInput={handleAddressChanges}
                        />
                        <ErrorMessage
                          name="foundation_zipcode"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="onboarding-auth-footer">
                    <div className="space-tow"></div>
                    <div className="space-tow">
                      <Button
                        className="efi-primary-btn w-100"
                        type="submit"
                        disabled={updateOnboardingDetails.buttonDisable || profile.data.onboarding == 4}
                      >
                        {updateOnboardingDetails.buttonDisable ? (
                          <ButtonLoader varient="black" />
                        ) : (
                          t("update")
                        )}
                      </Button>
                    </div>
                  </div>
                </FORM>
              )}
            </Formik>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default BusinessAccountDetails;
