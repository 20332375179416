export const ButtonLoader = () => {
  return (
    <div
      className="spinner-loader"
      style={{ display: "flex", margin: "auto" }}
    ></div>
  );
};

export const ButtonLoaderDark = () => {
  return (
    <div
      className="spinner-loader-dark"
      style={{ display: "flex", margin: "auto" }}
    ></div>
  );
};
