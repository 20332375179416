import React, { useState, useEffect, useRef } from "react";
import { Button, InputGroup, Table } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { beneficiaryListStart } from "../../store/slices/BeneficiariesSlice.jsx";
import Skeleton from "react-loading-skeleton";
import NoDataFoundNew from "../Helper/NoDataFoundNew.jsx";
import { useTranslation } from "react-multi-lang";
import Pagination from "../Helper/Pagination.jsx";
import MoneyTransferModal from "./MoneyTransferModal.jsx";
import QuoteTransferModal from "./QuoteTransfer.jsx";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage.jsx";
import CopyToClipboard from "react-copy-to-clipboard";
import iso from "iso-3166-1"
import Select from "react-select";
import { Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import { Tooltip } from 'react-tooltip';

const BeneficiaryList = (props) => {
  const navigate = useNavigate();
  const t = useTranslation("beneficiaries.beneficiaries_list");
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const beneficiaryList = useSelector(
    (state) => state.beneficiaries.beneficiaryList
  );
  const profile = useSelector((state) => state.admin.profile);
  const createQuote = useSelector((state) => state.beneficiaries.createQuote);
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [typeStatus, setTypeStatus] = useState(false);
  const [quoteModal, setQuoteModal] = useState(false);
  const [sendMoneyModal, setSendMoneyModal] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    recipient_type: "",
  });
  const [value, setValue] = useState([null, null]);

  const type_options = [
    { value: "1", label: "Individual" },
    { value: "2", label: "Business" },
  ];

  const validationSchema = Yup.object().shape({
    search_key: Yup.string()
      .required(t("required"))
      .matches(/^(?=\S*$)/, t("white_space")),
  });

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
    }
    setSkipRender(false);
  }, [searchParams.get("page")]);

  const handlePageClick = ({ selected }) => {
    navigate(`/beneficiary-list?page=${selected + 1}`);
  };


  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      // if(profile.data.onboarding >= 4){
        if(!beneficiaryList.buttonDisable){
          dispatch(
            beneficiaryListStart({
              ...filter,
              skip: 12 * (page - 1),
              take: 12,
            })
          );
        }
      // } else {
      //   getErrorNotificationMessage("Please complete onboarding to continue!")
      // }
    }
  }, [profile, filter, page]);

  useEffect(() => {
    if (
      !skipRender &&
      !createQuote.loading &&
      Object.keys(createQuote.data).length > 0
    ) {
      setSendMoneyModal(createQuote.data.quote);
    }
    setSkipRender(false);
  }, [createQuote]);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "180px",
      cursor: "pointer",
      fontSize: "0.95em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#878E96",
      fontSize: "0.95em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#0194ff",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.95em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#0194ff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#878E96",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? '#0194ff' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      ":hover": {
        backgroundColor: "#0194ff",
        color: "#fff"
      },
    }),
  };

  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="contact-list-card-area">
          <div className="payment-table-header justify-content-between">
            <h3>{t("heading")}</h3>
            {/* <div className="payment-table-header-right">
              {(profile.data.onboarding == 4 && (profile.data.mastercard_kyc_status == 3 || profile.data.iban_kyc_status == 3)) ? (
                <Button
                  className="efi-primary-btn"
                  disabled={beneficiaryList.buttonDisable}
                  onClick={() => navigate("/create-beneficiary")}
                >
                  {t("add_beneficiary")}
                </Button>
              ) : null}
            </div> */}
            {profile.data.onboarding >= 4 ?
            <div className="efi-feature-transation-search-frame">
              {profile.data.user_type == 2 ? <Select
                options={type_options}
                className="select-payout"
                styles={customStyles}
                isSearchable={false}
                isClearable
                value={typeStatus || null}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setFilter({
                      ...filter,
                      recipient_type: selectedOption.value,
                    });
                    setTypeStatus(selectedOption);
                    navigate(
                      filter.search_key
                        ? `/beneficiary-list?search_key=${filter.search_key}&recipient_type=${selectedOption.value}`
                        : `/beneficiary-list?recipient_type=${selectedOption.value}`
                    );
                  } else {
                    const newFilter = { ...filter };
                    delete newFilter.recipient_type;
                    setFilter(newFilter);
                    navigate(
                      filter.search_key
                        ? `/beneficiary-list?search_key=${filter.search_key}`
                        : "/beneficiary-list"
                    );
                    setTypeStatus(null);
                  }
                }}
                placeholder={
                  <div className="placeholder-flex">Recipient Type</div>
                }
                isDisabled={beneficiaryList.buttonDisable}
              /> : null}
              <div className="header-search">
                <Formik
                  initialValues={{
                    search_key: searchParams.get("search_key")
                      ? searchParams.get("search_key")
                      : "",
                    sort_by: searchParams.get("sort_by")
                      ? searchParams.get("sort_by")
                      : filter.sort_by,
                    from_date: "",
                    to_date: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(val) => {
                    setFilter({
                      ...filter,
                      search_key: val.search_key,
                      // from_date: dateValues.from_date,
                      // to_date: dateValues.to_date,
                    });

                    navigate(
                      searchParams.get("sort_by")
                        ? `/beneficiary-list?search_key=${val.search_key
                        }&sort_by=${searchParams.get("sort_by")}`
                        : `/beneficiary-list?search_key=${val.search_key}`,
                      { state: { value: value } }
                    );
                  }}
                  innerRef={formRef}
                >
                  {({ values, setFieldValue, resetForm }) => (
                    <FORM className="form">
                      {/* <InputGroup
                        className={`d-flex date-sec mb-0 efi-data-input ${close ? "date-close" : ""
                          }`}
                      >
                        <DatePicker
                          value={value}
                          style={{ padding: "1em" }}
                          onChange={(date) => {
                            handleDateChange(date);
                          }}
                          range
                          numberOfMonths={2}
                          ref={calenderRefs}
                          format="DD-MM-YYYY"
                          maxDate={
                            new Date(new Date().setHours(23, 59, 59, 999))
                          }
                          id="date-picker-input"
                          placeholder={t("date.date_range")}
                        // render={<InputIcon/>}
                          editable={false}
                          onOpenPickNewDate={false}
                          disabled={beneficiaryList.buttonDisable}
                          >
                          <div
                            className="d-flex justify-content-end gap-2"
                            style={{ padding: "1em" }}
                          >
                            <Button
                              className="efi-datePicker-btn-cancel"
                              onClick={() => {
                                calenderRefs.current.closeCalendar();
                                setDateValues({
                                  from_date: "",
                                  to_date: "",
                                });
                                setValue([null, null]);
                                setClose(false);
                                // setFilter({
                                //   ...filter,
                                //   from_date: "",
                                //   to_date: "",
                                // });
                              }}
                            >
                              {t("close")}
                            </Button>
                            <Button
                              className="efi-datePicker-btn"
                              onClick={() => {
                                if (values.from_date || values.to_date) {
                                  setFilter({
                                    ...filter,
                                    skip: 12 * (page - 1),
                                    take: 12,
                                    from_date: dateValues.from_date,
                                    to_date: dateValues.to_date,
                                  });
                                  calenderRefs.current.closeCalendar();
                                  setClose(true);
                                } else
                                  getErrorNotificationMessage(
                                    t("date.select_date")
                                  );
                              }}
                            >
                              {t("apply")}
                            </Button>
                            </div>
                          </DatePicker>
                          {close ? (
                            <>
                              <button
                                type="button"
                                className="search-btn date-cancel"
                                onClick={() => {
                                  // setValue([null , new Date()]);
                                  setDateValues({
                                    from_date: "",
                                    to_date: "",
                                  });
                                  setValue([null, null]);
                                  setClose(false);
                                  setFilter({
                                    ...filter,
                                    from_date: "",
                                    to_date: "",
                                  });
                                }}
                              >
                                <svg
                                  height="24"
                                  width="24"
                                  viewBox="0 0 20 20"
                                  aria-hidden="true"
                                  focusable="false"
                                  class="css-tj5bde-Svg"
                                >
                                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                </svg>
                              </button>
                            </>
                          ) : (
                            <InputGroup.Text
                              onClick={() => {
                                const input =
                                  document.getElementById("date-picker-input");
                                if (input) {
                                  input.focus();
                                }
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                fill="none"
                                viewBox="0 0 18 18"
                              >
                                <g clipPath="url(#clip0_47_6)">
                                  <path
                                    fill="#8F9899"
                                    d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_47_6">
                                    <path fill="#fff" d="M0 0H18V18H0z"></path>
                                  </clipPath>
                                </defs>
                              </svg>
                            </InputGroup.Text>
                          )}
                      </InputGroup> */}
                      <div className="efi-transation-search">
                        <InputGroup className="mb-0">
                          <Field
                            // placeholder={t("search_placeholder")}
                            placeholder={"Search"}
                            type="text"
                            className="form-control trans-form-control"
                            name="search_key"
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                formRef.current.handleSubmit();
                              }
                            }}
                            disabled={beneficiaryList.buttonDisable}
                          />
                          {searchParams.get("search_key") ? (
                            <InputGroup.Text id="basic-addon1">
                              <Button
                                className="search-btn"
                                onClick={() => {
                                  setFieldValue("search_key", "");
                                  setFilter({
                                    ...filter,
                                    search_key: "",
                                    // from_date: dateValues.from_date,
                                    // to_date: dateValues.to_date,
                                  });
                                  navigate(
                                    searchParams.get("sort_by")
                                      ? `/beneficiary-list?sort_by=${searchParams.get(
                                        "sort_by"
                                      )}`
                                      : `/beneficiary-list`
                                  );
                                }}
                              >
                                <svg
                                  height="20"
                                  width="20"
                                  viewBox="0 0 20 20"
                                  aria-hidden="true"
                                  focusable="false"
                                  class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                                >
                                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                </svg>
                              </Button>
                            </InputGroup.Text>
                          ) : (
                            <InputGroup.Text id="basic-addon1">
                              <Button className="search-btn" type="submit">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#333"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                // class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                                  <path d="M21 21l-6 -6" />
                                </svg>
                              </Button>
                            </InputGroup.Text>
                          )}
                        </InputGroup>
                      </div>
                    </FORM>
                  )}
                </Formik>
              </div>
              <Button
                  className="efi-primary-btn"
                  disabled={beneficiaryList.buttonDisable}
                  onClick={() => navigate("/create-beneficiary")}
                >
                  {t("add_beneficiary")}
              </Button>
            </div>
            :
            null}
          </div>
          {beneficiaryList.buttonDisable ? (
            <>
              {[...Array(8)].map((_, i) => (
                <Skeleton key={i} height={60} />
              ))}
            </>
          ) : Object.keys(beneficiaryList.data).length > 0 ? (
            beneficiaryList.data.total_beneficiary_accounts > 0 ? (
              <>
                <Table className="common-table" responsive>
                  <thead className="common-table-header">
                    <tr>
                      {/* <th>{t("unique_id")}</th> */}
                      <th>{t("account_name")}</th>
                      <th>{t("recipient_country")}</th>
                      <th>{t("account_type")}</th>
                      <th>{t("bank_name")}</th>
                      <th>{t("account_number")}</th>
                      <th>{t("status")}</th>
                      <th>{t("action")}</th>
                    </tr>
                  </thead>
                  <tbody className="common-table-body">
                    {beneficiaryList.data.beneficiary_accounts.map(
                      (account, index) => (
                        <tr key={index}>
                          {/* <td>{account.unique_id}</td> */}
                          <td>{account.account_name}</td>
                          {/* <td>{account.recipient_country}</td> */}
                          <td>{(iso.whereAlpha3(account.recipient_country).country.length > 20 ? (iso.whereAlpha3(account.recipient_country).country).slice(0,20) + "..." : (iso.whereAlpha3(account.recipient_country).country))}</td>
                          <td className="account_type">{account.account_type}</td>
                          <td>{account.additional_details.service_bank.length > 20 ? account.additional_details.service_bank.slice(0,20) + "..." : account.additional_details.service_bank}</td>
                          <td className="account-grid-copy">
                            {account.account_number.length > 15 ? account.account_number.slice(0,15) + "..." : account.account_number}
                              <a
                                className="my-anchor-element"
                                // data-tooltip-variant="error"
                              >
                                <CopyToClipboard>
                                  <Button
                                    className="copy-btn"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="#1f73b7"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      />
                                      <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                      <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                    </svg>
                                  </Button>
                                </CopyToClipboard>
                              </a>
                              <Tooltip anchorSelect=".my-anchor-element" place="bottom" className="tooltip-element-box">
                                Copy
                              </Tooltip>
                          </td>
                          <td>{account.status_formatted}</td>
                          <td
                            className="view-beneficiary-btn"
                            style={{
                              display: "grid",
                              gap: "0.5em",
                              flexDirection:"column",
                              justifyContent: "center",
                              // gridTemplateColumns:"repeat(2, 1fr)",
                            }}
                          >
                            <Button
                              className="efi-overlay-btn"
                              onClick={() =>
                                navigate(`/view-beneficiary/${account.unique_id}`)
                              }
                            >
                              {t("view")}
                            </Button>
                            {/* <Button
                              className="efi-overlay-btn"
                              onClick={() => setQuoteModal(account)}
                            >
                              Send Money
                            </Button> */}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
                {beneficiaryList.data.total_beneficiary_accounts > 12 ? (
                  <div className="new-billings-pagination-sec">
                    <Pagination
                      page={page}
                      pageCount={Math.ceil(
                        beneficiaryList.data.total_beneficiary_accounts / 12
                      )}
                      handlePageClick={handlePageClick}
                    />
                  </div>
                ) : null}
              </>
            ) : (
              <NoDataFoundNew />
            )
          ) : (
            <NoDataFoundNew />
          )}
        </div>
      </div>
      {quoteModal ? (
        <QuoteTransferModal
          show={quoteModal}
          onHide={() => setQuoteModal(false)}
        />
      ) : null}
      {sendMoneyModal ? (
        <MoneyTransferModal
          show={sendMoneyModal}
          onHide={() => setSendMoneyModal(false)}
        />
      ) : null}
    </>
  );
};

export default BeneficiaryList;