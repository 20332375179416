import React, { useState, useEffect } from "react";
import { Button, Image, InputGroup, Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation } from "react-multi-lang";
// import {
//   cardBlockUnblockStart,
//   cardViewStart,
//   physicalCardAssignStart,
//   virtualCardCreateStart,
// } from "../../store/slices/CardSlice";
// import { transferMoneyStart } from "../../store/";
// import CopyToClipboard from "react-copy-to-clipboard";
import * as Yup from "yup";
import { generateBackupCodesStart } from "../../store/slices/UserAccessSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const OtpVerification = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("settings.setting");
  const cardView = useSelector((state) => state.card.cardView);
  const [otp, setOtp] = useState("");
  const cardBlockUnblock = useSelector((state) => state.card.cardBlockUnblock);
  const [skipRender, setSkipRender] = useState(true);
  const transferMoney = useSelector((state) => state.payment.transferMoney);
  const [tfaShowPassword, setTfaShowPassword] = useState(true);
  const generateBackupCodes = useSelector(state => state.access.generateBackupCodes)

  const tfaSchema = Yup.object().shape({
    otp: Yup.string().required(t("required")),
    password: Yup.string()
      .required(t("required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid")),
  });

  const handleSubmit = (values) => {
    // e.preventDefault();
    if (otp) {
      if (otp.length === 6) {
        dispatch(generateBackupCodesStart({
          ...values,
          otp: otp
        }
        ));
      } else {
        getErrorNotificationMessage(
          "The verification code should be 6 digits."
        );
      }
    } else {
      getErrorNotificationMessage("The verification code field is required.");
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !cardBlockUnblock.loading &&
      Object.keys(cardBlockUnblock.data).length > 0
    ) {
      props.closeOtpVerifyModal();
    }
    setSkipRender(false);
  }, [cardBlockUnblock]);

  const handleChange = (otp) => {
    // Check if the input is numeric
    if (/^\d*$/.test(otp)) {
      setOtp(otp);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="proxy-card-input-modal"
        backdrop="static"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="sign-up-verification">
            <div className="otp-frame-title mt-3">
              <LazyLoadImage
                    effect="blur"
                src={window.location.origin + "/img/feature/otp-icon.svg"}
              />
              <h2> Input 2FA code from virtual device.</h2>
              <p>
                Please input the code shown on your virtual device{" "}
                {/* {props.showOtpVerify.action == 4 ? "card details" : props.showOtpVerify.action == 5 ? "money transfer" : props.showOtpVerify.action == 3 ? "unblocking card" : "blocking card" } */}
              </p>
            </div>
            <Formik
              initialValues={{
                otp: "",
                password: "",
              }}
              onSubmit={handleSubmit}
              validationSchema={tfaSchema}
            >
              {({ values, setFieldValue }) => (
                <FORM className="efi-bank-default-form">
                  <ul className="list-unstyled">
                    <li>
                      <div className="efi-bank-step-authentic-card">
                        <div className="efi-bank-step-count">1</div>
                        <div className="efi-bank-step-info">
                          <p>{t("enter_six_configuration_code")}</p>
                          <div className="">
                            <OtpInput
                              className="otp-box"
                              value={otp}
                              onChange={handleChange}
                              numInputs={6}
                              // renderSeparator={<span>-</span>}
                              renderInput={(props) => <input {...props} />}
                              isInputNum={true} // Restricts input to numbers only
                              shouldAutoFocus // Automatically focuses on the first input
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="efi-bank-step-authentic-card">
                        <div className="efi-bank-step-count">2</div>
                        <div className="efi-bank-default-form ">
                          <p>{t("password.enter_your_password")}</p>
                          <div className="mb-3" style={{ width: "100%" }}>
                            <InputGroup>
                              <Field
                                className="form-control"
                                name="password"
                                type={!tfaShowPassword ? "text" : "password"}
                                placeholder={t("password.placeholder")}
                                aria-label="Password"
                                aria-describedby="Password-addon"
                              />
                              <InputGroup.Text
                                className="password-icon"
                                onClick={() =>
                                  setTfaShowPassword(!tfaShowPassword)
                                }
                              >
                                {!tfaShowPassword ? (
                                  <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="#c3c7ca"
                                    d="M21.25 9.15C18.94 5.52 15.56 3.43 12 3.43c-1.78 0-3.51.52-5.09 1.49-1.58.98-3 2.41-4.16 4.23-1 1.57-1 4.12 0 5.69 2.31 3.64 5.69 5.72 9.25 5.72 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69zM12 16.04c-2.24 0-4.04-1.81-4.04-4.04S9.76 7.96 12 7.96s4.04 1.81 4.04 4.04-1.8 4.04-4.04 4.04z"
                                  ></path>
                                  <path
                                    fill="#171717"
                                    d="M12 9.14a2.855 2.855 0 000 5.71c1.57 0 2.86-1.28 2.86-2.85S13.57 9.14 12 9.14z"
                                  ></path>
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="#c3c7ca"
                                    d="M21.25 9.15a14.57 14.57 0 00-1.63-2.11l-3.77 3.77c.12.37.19.77.19 1.19 0 2.24-1.81 4.04-4.04 4.04-.42 0-.82-.07-1.19-.19l-3.46 3.46c1.46.82 3.04 1.25 4.65 1.25 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69z"
                                  ></path>
                                  <path
                                    fill="#171717"
                                    d="M14.02 9.98l-4.04 4.04c-.51-.52-.84-1.24-.84-2.02 0-1.57 1.28-2.86 2.86-2.86.78 0 1.5.33 2.02.84z"
                                  ></path>
                                  <path
                                    fill="#c3c7ca"
                                    d="M18.25 5.75l-3.39 3.39A3.986 3.986 0 0012 7.96c-2.24 0-4.04 1.81-4.04 4.04 0 1.12.45 2.13 1.18 2.86l-3.38 3.39h-.01c-1.11-.9-2.13-2.05-3-3.41-1-1.57-1-4.12 0-5.69C3.91 7.33 5.33 5.9 6.91 4.92c1.58-.96 3.31-1.49 5.09-1.49 2.23 0 4.39.82 6.25 2.32z"
                                  ></path>
                                  <path
                                    fill="#171717"
                                    d="M14.86 12c0 1.57-1.28 2.86-2.86 2.86-.06 0-.11 0-.17-.02l3.01-3.01c.02.06.02.11.02.17zM21.77 2.23c-.3-.3-.79-.3-1.09 0L2.23 20.69c-.3.3-.3.79 0 1.09a.758.758 0 001.08-.01L21.77 3.31c.31-.3.31-.78 0-1.08z"
                                  ></path>
                                </svg>
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="password"
                              className="errorMsg"
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="efi-bank-default-form">
                    <div className="form-modal-footer">
                      <Button
                        type="submit"
                        className="efi-primary-btn w-100"
                        onClick={() => handleSubmit(values)}
                        disabled={generateBackupCodes.buttonDisable}
                      >
                        {generateBackupCodes.buttonDisable ? (
                          <ButtonLoader />
                        ) : (
                          t("generate")
                        )}
                      </Button>
                    </div>
                  </div>
                </FORM>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OtpVerification;
