import React, { useState, useEffect } from "react";
import { Button, Table, Dropdown } from "react-bootstrap";
import ContactCreateModal from "./ContactCreateModal";
import ContactDeleteModal from "./ContactDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { contactsListStart } from "../../store/slices/AccountsSlice";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";
import configuration from "react-global-configuration";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import SendMoneyModal from "./SendMoneyModal";
import OtpVerification from "../Card/OtpVerification";

const ContactIndex = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector((state) => state.admin.profile);
  const contactsList = useSelector((state) => state.accounts.contactsList);

  const [modalShow, deleteModalShow] = React.useState(false);
  const [createmodalShow, createModalShow] = React.useState(false);
  const [moneyModal, setMoneyModal] = useState(false);
  const [showOTPVerifyModal, setShowOTPVerifyModal] = useState(false);

  useEffect(() => {
    dispatch(contactsListStart());
  }, []);

  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="contact-list-card-area">
          <div className="payment-table-header justify-content-between">
            <h3>Within Pay Users List</h3>
            <div className="payment-table-header-right">
              <Button
                className="efi-primary-btn"
                onClick={() =>
                  profile.data.onboarding != 4
                    ? getErrorNotificationMessage(
                        "Please complete onboarding first!"
                      )
                    : configuration.get("configData.stylopay_service_status") ==
                      1
                    ? createModalShow(true)
                    : getErrorNotificationMessage(
                        "Service not available, Please try again later!"
                      )
                }
              >
                Create Within Pay User
              </Button>
            </div>
          </div>
          {contactsList.loading ? (
            <>
              {[...Array(3)].map((_, key) => (
                <Skeleton key={key} height={50} width={"100%"} />
              ))}
            </>
          ) : Object.keys(contactsList.data).length > 0 ? (
            contactsList.data.m_contacts.length > 0 ? (
              <Table className="common-table" responsive>
                <thead className="common-table-header">
                  <tr>
                    <th>Within Pay User Id</th>
                    {/* <th>IBAN</th> */}
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile Number</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody className="common-table-body">
                  {contactsList.data.m_contacts.map((item, i) => (
                    <tr key={i}>
                      <td>{item.unique_id}</td>
                      {/* <td>{item.iban}</td> */}
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.mobile}</td>
                      <td className="delete-contact-btn-sec">
                        <Link
                          to={`/contact-details-view/${item.unique_id}`}
                        >
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <g fill="#000">
                              <path
                                d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.908 4.908 0 000 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.908 4.908 0 000-5.162zm-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.918 2.918 0 010-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.918 2.918 0 010 3.068z"
                                data-original="#000000"
                              ></path>
                              <path
                                d="M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z"
                                data-original="#000000"
                              ></path>
                            </g>
                          </svg>
                        </Link>
                        <Link
                          onClick={() =>
                            profile.data.onboarding != 4
                              ? getErrorNotificationMessage(
                                  "Please complete onboarding first!"
                                )
                              : setMoneyModal(item)
                          }
                        >
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                          >
                            <g fill="#000">
                              <path
                                d="M7 18c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l10-10c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-10 10c-.2.2-.4.3-.7.3z"
                                data-original="#000000"
                              ></path>
                              <path
                                d="M17 17c-.6 0-1-.4-1-1V8H8c-.6 0-1-.4-1-1s.4-1 1-1h9c.6 0 1 .4 1 1v9c0 .6-.4 1-1 1z"
                                data-original="#000000"
                              ></path>
                            </g>
                          </svg>
                        </Link>
                        <Link
                          onClick={() =>
                            profile.data.onboarding != 4
                              ? getErrorNotificationMessage(
                                  "Please complete onboarding first!"
                                )
                              : configuration.get(
                                  "configData.stylopay_service_status"
                                ) == 1
                              ? deleteModalShow(item)
                              : getErrorNotificationMessage(
                                  "Service not available, Please try again later!"
                                )
                          }
                        >
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="23"
                            viewBox="0 0 32 32"
                          >
                            <g>
                              <path d="M19.5 23.75a1 1 0 001-1v-10a1 1 0 00-2 0v10a1 1 0 001 1zM12.5 23.75a1 1 0 001-1v-10a1 1 0 00-2 0v10a1 1 0 001 1zM20 4.25a1 1 0 000-2h-8a1 1 0 000 2z"></path>
                              <path d="M5 5.25a1 1 0 000 2h1v18.4c0 2.26 1.84 4.1 4.1 4.1h11.8c2.26 0 4.1-1.84 4.1-4.1V7.25h1a1 1 0 000-2H7zm19 2v18.4a2.1 2.1 0 01-2.1 2.1H10.1a2.1 2.1 0 01-2.1-2.1V7.25z"></path>
                            </g>
                          </svg>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <NoDataFound />
            )
          ) : null}
        </div>
      </div>
      {modalShow && (
        <ContactDeleteModal
          show={modalShow}
          onHide={() => deleteModalShow(false)}
        />
      )}
      {createModalShow && (
        <ContactCreateModal
          show={createmodalShow}
          onHide={() => createModalShow(false)}
        />
      )}
      {moneyModal ? (
        <SendMoneyModal show={moneyModal} onHide={() => setMoneyModal(false)} setShowOTPVerifyModal={setShowOTPVerifyModal} />
      ) : null}
      {showOTPVerifyModal ? (
        <OtpVerification
          showOtpVerify={showOTPVerifyModal}
          closeOtpVerifyModal={() => setShowOTPVerifyModal(false)}
        />
      ) : null}
    </>
  );
};
export default ContactIndex;
