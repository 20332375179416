import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  InputGroup,
  Image,
  Row,
  Col,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { beneficiaryListStart } from "../../store/slices/BeneficiariesSlice";
import Skeleton from "react-loading-skeleton";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import Pagination from "../Helper/Pagination";
import NoDataFound from "../Helper/NoDataFound";
import NoDataFoundNew from "../Helper/NoDataFoundNew";
// import "./modal.css";

const SelectBeneficiaryModal = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("quote.quote_transfer");
  const formRef = React.useRef();
  const beneficiaryList = useSelector(
    (state) => state.beneficiaries.beneficiaryList
  );
  const sendMoneyData = useSelector((state) => state.beneficiaries.sendMoneyData);

  const [filter, setFilter] = useState({
    search_key: "",
  });
  const [page, setPage] = useState(1);

  const validationSchema = Yup.object().shape({
    beneficiary_account_id: Yup.number().required(t("required")),
  });

  // useEffect(() => {
  //   props.transferData &&
  //     !props.transferData.beneficiary_account_id &&
  //     dispatch(
  //       beneficiaryListStart({
  //         receiving_currency: props.transferData.receiving_currency,
  //         recipient_type: props.transferData.recipient_type,
  //         recipient_country: props.transferData.recipient_country,
  //       })
  //     );
  //   console.log(props.transferData);
  // }, [props.transferData]);

  useEffect(() => {
    if (props.transferData) {
      if (!beneficiaryList.buttonDisable) {
        dispatch(
          beneficiaryListStart({
            ...filter,
            skip: 3 * (page - 1),
            take: 3,
            receiving_currency: props.transferData.receiving_currency,
            recipient_type: props.transferData.recipient_type,
            recipient_country: props.transferData.recipient_country,
          })
        );
      }
    }
  }, [filter, page, props.transferData]);

  const handlePageClick = ({ selected }) => {
    // let params = filter;
    setPage(selected + 1);
  };

  const handleSubmit = (values) => {
    props.setSelectedBeneficiary(beneficiaryList.data.beneficiary_accounts.find((item)=> item.id == values.beneficiary_account_id));
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="select-beneficiary-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body scrollable>
        <div className="select-beneficiary-head">
          <div className="select-beneficiary-head-titles">
            <h3>Select Beneficiary</h3>
          </div>
          <div className="select-beneficiary-head-search">
            <Formik
              initialValues={{
                search_key: filter.search_key,
              }}
              validationSchema={validationSchema}
              onSubmit={(values) =>
                setFilter({
                  search_key: values.search_key,
                })
              }
            >
              {({ values, setFieldValue, resetForm }) => (
                <InputGroup className="mb-0">
                  <Field
                    placeholder={"Search"}
                    type="text"
                    className="form-control trans-form-control"
                    name="search_key"
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        setFilter({
                          search_key: values.search_key,
                        });
                      }
                    }}
                    disabled={beneficiaryList.buttonDisable}
                  />
                  {filter.search_key ? (
                    <InputGroup.Text id="basic-addon1">
                      <Button
                        className="search-btn"
                        onClick={() => {
                          setFieldValue("search_key", "");
                          setFilter({
                            ...filter,
                            search_key: "",
                          });
                        }}
                      >
                        <svg
                          height="20"
                          width="20"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                          focusable="false"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                        >
                          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                        </svg>
                      </Button>
                    </InputGroup.Text>
                  ) : (
                    <InputGroup.Text id="basic-addon1">
                      <Button
                        className="search-btn"
                        type="button"
                        onClick={() =>
                          setFilter({ search_key: values.search_key })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#333"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          // class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                          <path d="M21 21l-6 -6" />
                        </svg>
                      </Button>
                    </InputGroup.Text>
                  )}
                </InputGroup>
              )}
            </Formik>
          </div>
        </div>
        <div
          className=""
          style={{
            maxHeight: "calc(100vh - 220px)",
            overflowY: "auto",
            padding: "1em",
            //   marginTop: '1em'
          }}
        >
          <div className="select-beneficiary-body-card">
            {beneficiaryList.loading ? (
              [...Array(3)].map((i, key) => (
                <Skeleton height={80} width={"100%"} />
              ))
            ) : Object.keys(beneficiaryList.data).length > 0 ? (
              <>
                <Formik
                  initialValues={{
                    beneficiary_account_id: sendMoneyData.beneficiary_account_id || "",
                    quote_id: props.transferData.quote.id,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                  innerRef={formRef}
                >
                  {({ setFieldValue, values, touched, errors, resetForm }) => (
                    <FORM className="efi-bank-default-form w-100">
                      <Row>
                        <Col md={12}>
                          {beneficiaryList.data.beneficiary_accounts.length >
                          0 ? (
                            beneficiaryList.data.beneficiary_accounts.map(
                              (item, index) => (
                                <div class="form-check select-beneficiary-check mb-3">
                                  <label
                                    class="form-check-label w-100"
                                    for={`flexRadioDefault${index}`}
                                  >
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id={`flexRadioDefault${index}`}
                                      checked={
                                        values.beneficiary_account_id == item.id
                                      }
                                      onClick={() => {
                                        setFieldValue("beneficiary_account_id",item.id);
                                      }}
                                    />
                                    <div className="select-bank-card-input">
                                      <div className="select-bank-card-input-info">
                                        <div className="select-bank-card-bank-name">
                                          {item.account_name}
                                        </div>
                                        <div className="select-bank-card-bank-info">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            fill="none"
                                            viewBox="0 0 17 18"
                                          >
                                            <path
                                              fill="#030618"
                                              d="M1.79 8.888v3.471c0 .72.599 1.302 1.342 1.302.742 0 1.342-.581 1.342-1.302V8.89c0-.721-.6-1.302-1.342-1.302-.743 0-1.343.581-1.343 1.301zm5.368 0v3.471c0 .72.6 1.302 1.342 1.302.743 0 1.342-.581 1.342-1.302V8.89c0-.721-.6-1.302-1.342-1.302-.743 0-1.342.581-1.342 1.301zM1.342 18h14.316C16.4 18 17 17.419 17 16.698c0-.72-.6-1.301-1.342-1.301H1.342C.6 15.397 0 15.978 0 16.698S.6 18 1.342 18zm11.184-9.112v3.471c0 .72.6 1.302 1.342 1.302.743 0 1.342-.581 1.342-1.302V8.89c0-.721-.599-1.302-1.342-1.302-.742 0-1.342.581-1.342 1.301zM7.668.202L.599 3.812C.233 3.994 0 4.367 0 4.766c0 .6.501 1.085 1.118 1.085H15.89c.609 0 1.11-.486 1.11-1.085 0-.399-.233-.772-.6-.954L9.333.202a1.817 1.817 0 00-1.664 0z"
                                            ></path>
                                          </svg>
                                          {item.additional_details.service_bank}
                                        </div>
                                      </div>
                                      <div className="select-bank-card-input-info">
                                        <div className="select-bank-card-bank-no">
                                          {item.account_number}
                                        </div>
                                        <div className="select-bank-card-bank-info">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="none"
                                            viewBox="0 0 20 21"
                                          >
                                            <path
                                              fill="url(#paint0_linear_5178_869)"
                                              d="M10 .5C4.477.5 0 4.977 0 10.5s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10zm-.582 8.48c.182.188.473.368.875.538.402.171.782.348 1.139.532.356.184.665.392.924.626.26.233.462.506.61.817.147.311.22.685.22 1.122 0 .74-.235 1.342-.704 1.81-.469.466-1.107.739-1.916.816v1.304H9.535v-1.31c-.943-.1-1.666-.423-2.17-.97-.503-.547-.755-1.268-.755-2.163h2.186c0 .493.109.868.327 1.125.218.258.529.386.93.386.295 0 .526-.087.694-.262.169-.175.253-.416.253-.723 0-.342-.084-.61-.253-.805-.168-.194-.465-.378-.888-.55a18.166 18.166 0 01-1.174-.526 4.08 4.08 0 01-.928-.616 2.42 2.42 0 01-.596-.81c-.138-.308-.207-.68-.207-1.116 0-.73.246-1.333.739-1.807.493-.473 1.15-.749 1.971-.827V4.203h1.032v1.395c.79.116 1.412.442 1.864.976.452.534.678 1.212.678 2.033h-2.192c0-.45-.087-.795-.26-1.037-.173-.243-.42-.364-.74-.364-.284 0-.506.09-.664.27-.158.179-.237.426-.237.742s.09.574.273.762z"
                                            ></path>
                                            <defs>
                                              <linearGradient
                                                id="paint0_linear_5178_869"
                                                x1="2.929"
                                                x2="17.071"
                                                y1="3.429"
                                                y2="17.571"
                                                gradientUnits="userSpaceOnUse"
                                              >
                                                <stop stopColor="#FDE560"></stop>
                                                <stop
                                                  offset="1"
                                                  stopColor="#FF6F00"
                                                ></stop>
                                              </linearGradient>
                                            </defs>
                                          </svg>
                                          {item.receiving_currency}
                                        </div>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              )
                            )
                          ) : (
                            <NoDataFoundNew />
                          )}
                        </Col>
                      </Row>
                      <div className="onboarding-auth-footer mt-3">
                        <Button
                          className="efi-primary-btn w-100"
                          type="submit"
                          disabled={!values.beneficiary_account_id}
                        >
                          Select Beneficiary
                        </Button>
                      </div>
                    </FORM>
                  )}
                </Formik>
                {beneficiaryList.data.total_beneficiary_accounts > 3 ? (
                  <div className="new-billings-pagination-sec">
                    <Pagination
                      page={page}
                      pageCount={Math.ceil(
                        beneficiaryList.data.total_beneficiary_accounts / 3
                      )}
                      handlePageClick={handlePageClick}
                    />
                  </div>
                ) : null}
              </>
            ) : (
              <div className="single-transaction-box-action margin-top-sm">
                <Button
                  type="button"
                  className="efi-primary-btn w-100 mt-3"
                  disabled={beneficiaryList.buttonDisable}
                  onClick={() =>
                    dispatch(
                      beneficiaryListStart({
                        receiving_currency:
                          props.transferData.receiving_currency,
                        recipient_type: props.transferData.recipient_type,
                      })
                    )
                  }
                >
                  Retry
                </Button>
              </div>
            )}
            {/* <div class="form-check select-beneficiary-check">
              <label class="form-check-label w-100" for="flexRadioDefault1">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <div className="select-bank-card-input">
                  <div className="select-bank-card-input-info">
                    <div className="select-bank-card-bank-name">
                      Amber Caloy
                    </div>
                    <div className="select-bank-card-bank-info">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="none"
                        viewBox="0 0 17 18"
                      >
                        <path
                          fill="#030618"
                          d="M1.79 8.888v3.471c0 .72.599 1.302 1.342 1.302.742 0 1.342-.581 1.342-1.302V8.89c0-.721-.6-1.302-1.342-1.302-.743 0-1.343.581-1.343 1.301zm5.368 0v3.471c0 .72.6 1.302 1.342 1.302.743 0 1.342-.581 1.342-1.302V8.89c0-.721-.6-1.302-1.342-1.302-.743 0-1.342.581-1.342 1.301zM1.342 18h14.316C16.4 18 17 17.419 17 16.698c0-.72-.6-1.301-1.342-1.301H1.342C.6 15.397 0 15.978 0 16.698S.6 18 1.342 18zm11.184-9.112v3.471c0 .72.6 1.302 1.342 1.302.743 0 1.342-.581 1.342-1.302V8.89c0-.721-.599-1.302-1.342-1.302-.742 0-1.342.581-1.342 1.301zM7.668.202L.599 3.812C.233 3.994 0 4.367 0 4.766c0 .6.501 1.085 1.118 1.085H15.89c.609 0 1.11-.486 1.11-1.085 0-.399-.233-.772-.6-.954L9.333.202a1.817 1.817 0 00-1.664 0z"
                        ></path>
                      </svg>
                      HDFC Bank
                    </div>
                  </div>
                  <div className="select-bank-card-input-info">
                    <div className="select-bank-card-bank-no">
                      50100317152468
                    </div>
                    <div className="select-bank-card-bank-info">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 20 21"
                      >
                        <path
                          fill="url(#paint0_linear_5178_869)"
                          d="M10 .5C4.477.5 0 4.977 0 10.5s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10zm-.582 8.48c.182.188.473.368.875.538.402.171.782.348 1.139.532.356.184.665.392.924.626.26.233.462.506.61.817.147.311.22.685.22 1.122 0 .74-.235 1.342-.704 1.81-.469.466-1.107.739-1.916.816v1.304H9.535v-1.31c-.943-.1-1.666-.423-2.17-.97-.503-.547-.755-1.268-.755-2.163h2.186c0 .493.109.868.327 1.125.218.258.529.386.93.386.295 0 .526-.087.694-.262.169-.175.253-.416.253-.723 0-.342-.084-.61-.253-.805-.168-.194-.465-.378-.888-.55a18.166 18.166 0 01-1.174-.526 4.08 4.08 0 01-.928-.616 2.42 2.42 0 01-.596-.81c-.138-.308-.207-.68-.207-1.116 0-.73.246-1.333.739-1.807.493-.473 1.15-.749 1.971-.827V4.203h1.032v1.395c.79.116 1.412.442 1.864.976.452.534.678 1.212.678 2.033h-2.192c0-.45-.087-.795-.26-1.037-.173-.243-.42-.364-.74-.364-.284 0-.506.09-.664.27-.158.179-.237.426-.237.742s.09.574.273.762z"
                        ></path>
                        <defs>
                          <linearGradient
                            id="paint0_linear_5178_869"
                            x1="2.929"
                            x2="17.071"
                            y1="3.429"
                            y2="17.571"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#FDE560"></stop>
                            <stop offset="1" stopColor="#FF6F00"></stop>
                          </linearGradient>
                        </defs>
                      </svg>
                      USD
                    </div>
                  </div>
                </div>
              </label>
            </div>
            <div class="form-check select-beneficiary-check">
              <label class="form-check-label w-100" for="flexRadioDefault2">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked
                />
                <div className="select-bank-card-input">
                  <div className="select-bank-card-input-info">
                    <div className="select-bank-card-bank-name">
                      Amber Caloy
                    </div>
                    <div className="select-bank-card-bank-info">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="none"
                        viewBox="0 0 17 18"
                      >
                        <path
                          fill="#030618"
                          d="M1.79 8.888v3.471c0 .72.599 1.302 1.342 1.302.742 0 1.342-.581 1.342-1.302V8.89c0-.721-.6-1.302-1.342-1.302-.743 0-1.343.581-1.343 1.301zm5.368 0v3.471c0 .72.6 1.302 1.342 1.302.743 0 1.342-.581 1.342-1.302V8.89c0-.721-.6-1.302-1.342-1.302-.743 0-1.342.581-1.342 1.301zM1.342 18h14.316C16.4 18 17 17.419 17 16.698c0-.72-.6-1.301-1.342-1.301H1.342C.6 15.397 0 15.978 0 16.698S.6 18 1.342 18zm11.184-9.112v3.471c0 .72.6 1.302 1.342 1.302.743 0 1.342-.581 1.342-1.302V8.89c0-.721-.599-1.302-1.342-1.302-.742 0-1.342.581-1.342 1.301zM7.668.202L.599 3.812C.233 3.994 0 4.367 0 4.766c0 .6.501 1.085 1.118 1.085H15.89c.609 0 1.11-.486 1.11-1.085 0-.399-.233-.772-.6-.954L9.333.202a1.817 1.817 0 00-1.664 0z"
                        ></path>
                      </svg>
                      HDFC Bank
                    </div>
                  </div>
                  <div className="select-bank-card-input-info">
                    <div className="select-bank-card-bank-no">
                      50100317152468
                    </div>
                    <div className="select-bank-card-bank-info">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 20 21"
                      >
                        <path
                          fill="url(#paint0_linear_5178_869)"
                          d="M10 .5C4.477.5 0 4.977 0 10.5s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10zm-.582 8.48c.182.188.473.368.875.538.402.171.782.348 1.139.532.356.184.665.392.924.626.26.233.462.506.61.817.147.311.22.685.22 1.122 0 .74-.235 1.342-.704 1.81-.469.466-1.107.739-1.916.816v1.304H9.535v-1.31c-.943-.1-1.666-.423-2.17-.97-.503-.547-.755-1.268-.755-2.163h2.186c0 .493.109.868.327 1.125.218.258.529.386.93.386.295 0 .526-.087.694-.262.169-.175.253-.416.253-.723 0-.342-.084-.61-.253-.805-.168-.194-.465-.378-.888-.55a18.166 18.166 0 01-1.174-.526 4.08 4.08 0 01-.928-.616 2.42 2.42 0 01-.596-.81c-.138-.308-.207-.68-.207-1.116 0-.73.246-1.333.739-1.807.493-.473 1.15-.749 1.971-.827V4.203h1.032v1.395c.79.116 1.412.442 1.864.976.452.534.678 1.212.678 2.033h-2.192c0-.45-.087-.795-.26-1.037-.173-.243-.42-.364-.74-.364-.284 0-.506.09-.664.27-.158.179-.237.426-.237.742s.09.574.273.762z"
                        ></path>
                        <defs>
                          <linearGradient
                            id="paint0_linear_5178_869"
                            x1="2.929"
                            x2="17.071"
                            y1="3.429"
                            y2="17.571"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#FDE560"></stop>
                            <stop offset="1" stopColor="#FF6F00"></stop>
                          </linearGradient>
                        </defs>
                      </svg>
                      USD
                    </div>
                  </div>
                </div>
              </label>
            </div> */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SelectBeneficiaryModal;