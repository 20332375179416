import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Image,
  InputGroup,
} from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
  getCountries,
} from "react-phone-number-input";
import {
  contactsStoreStart,
  findUserStart,
} from "../../store/slices/AccountsSlice";
import { ButtonLoader } from "../Helper/Loader";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import { businessUserListStart } from "../../store/slices/BusinessSlice";
import Select from "react-select";
import { Radio } from "pretty-checkbox-react";

const ContactCreateModal = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("contact.contact_create");
  const formRef = useRef();
  const [skipRender, setSkipRender] = useState(true);
  const contactsStore = useSelector((state) => state.accounts.contactsStore);
  const findUser = useSelector((state) => state.accounts.findUser);
  const businessUserList = useSelector(
    (state) => state.business.businessUserList
  );
  const [userFound, setUserFound] = useState(false);
  const profile = useSelector((state) => state.admin.profile);
  const [businessUserOptions, setBusinessUserOptions] = useState([]);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid #dbdbdb!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "300",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#0194ff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#757575",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#0194ff" : "#fff",
      color: state.isFocused || state.isSelected ? "#fff" : "#000",
      ":hover": {
        backgroundColor: "#0194ff",
        color: "#fff",
      },
    }),
  };

  const validationSchema = Yup.object().shape({
    // name: Yup.string()
    //   .required(t("full_name.required"))
    //   .matches(/^\S.*$/, t("full_name.required"))
    //   .trim(),
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    // mobile: Yup.string()
    //   .required(t("phone_number.required"))
    //   .matches(/^(?=.*[0-9])/, t("phone_number.invalid")),
  });

  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("phone_number.invalid"));
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("phone_number.invalid"));
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("phone_number.required"));
      return false;
    }
  };

  const handleSubmit = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      dispatch(
        contactsStoreStart({
          ...values,
          mobile: mobile,
          mobile_country_code: countryCode,
        })
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !contactsStore.loading &&
      Object.keys(contactsStore.data).length > 0
    ) {
      props.onHide();
    }
    setSkipRender(false);
  }, [contactsStore]);

  useEffect(() => {
    if (
      !skipRender &&
      !findUser.loading &&
      Object.keys(findUser.data).length > 0
    ) {
      formRef.current?.setFieldValue("name", findUser.data.name);
      formRef.current?.setFieldValue(
        "mobile",
        `+${findUser.data.mobile_country_code}${findUser.data.mobile}`
      );
      setUserFound(true);
    }
    setSkipRender(false);
  }, [findUser]);

  useEffect(() => {
    if (
      !profile.buttonDisable &&
      Object.keys(profile.data).length > 0 &&
      profile.data.user_type == 2
    ) {
      dispatch(businessUserListStart());
    }
  }, [profile]);

  useEffect(() => {
    if (
      !skipRender &&
      !businessUserList.buttonDisable &&
      Object.keys(businessUserList.data).length > 0
    ) {
      let options = businessUserList.data.users.map((item) => ({
        value: item,
        label:
          item.title +
          " " +
          item.first_name +
          " " +
          item.middle_name +
          " " +
          item.last_name,
      }));
      setBusinessUserOptions(options);
    }
    setSkipRender(false);
  }, [businessUserList]);

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="contact-created-modal"
        backdrop="static"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="create-beneficiary-form-heading mb-3">
            <Image
              className="modal-popup-icon"
              src={window.location.origin + "/img/create-contact-icon.svg"}
              type="image/png"
            />
          </div>
          <h3 className="change-password-heading mb-4">Create Within Pay User</h3>
          <Formik
            initialValues={{
              user_type: profile.data.user_type,
              recipient_type: 1,
              name: "",
              email: "",
              mobile: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            {({ setFieldValue, values, touched, errors, isValid }) => (
              <FORM className="payament-form-input">
                <div className="efi-bank-default-form">
                  <Row>
                    {/* {profile.data.user_type == 2 ? (
                      <Col md={12}>
                        <Form.Group className="mb-3">
                          <Form.Label className="default-form-label">
                            Business User
                          </Form.Label>
                          <Select
                            styles={customStyles}
                            // defaultValue={selectedTitle}
                            onChange={(option) => {
                              if (Object.keys(option.value).length > 0) {
                                setFieldValue(
                                  "name",
                                  option.value.first_name +
                                    " " +
                                    option.value.middle_name +
                                    " " +
                                    option.value.last_name
                                );
                                setFieldValue("email", option.value.email);
                                setFieldValue(
                                  "mobile",
                                  `+${option.value.mobile_country_code}${option.value.mobile}`
                                );
                              }
                            }}
                            options={businessUserOptions}
                            name="email"
                            placeholder={<>Select User</>}
                            isSearchable={false}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="errorMsg"
                          />
                        </Form.Group>
                      </Col>
                    ) : ( */}
                      <Col md={12}>
                        {values.user_type == 2 ? <div>
                          <Form.Label className="default-form-label">
                            Type
                          </Form.Label>
                          <Form.Group className="mb-3">
                            <Radio
                              name="radio"
                              onClick={(e) => {
                                setFieldValue("recipient_type", e.target.value);
                              }}
                              value="1"
                              checked={values.recipient_type == 1}
                            >
                              {t("individual")}
                            </Radio>
                            <Radio
                              name="radio"
                              onClick={(e) => {
                                setFieldValue("recipient_type", e.target.value);
                              }}
                              value="2"
                              checked={values.recipient_type == 2}
                              // disabled
                            >
                              {t("business")}
                            </Radio>
                            <ErrorMessage
                              component={"div"}
                              name="recipient_type"
                              className="errorMsg"
                            />
                          </Form.Group>
                        </div>: null}
                        <div className="mb-3">
                          <InputGroup>
                            <Form.Label className="default-form-label w-100">
                              Email
                            </Form.Label>
                            <Field
                              className="form-control new-control"
                              name="email"
                              type="email"
                              placeholder="Email"
                              aria-label="Email"
                              aria-describedby="email-addon"
                              onChange={(e) => {
                                setFieldValue("email", e.target.value);
                                setUserFound(false);
                              }}
                            />
                            <InputGroup.Text id="basic-addon2">
                              <Button
                                className="lay-btn"
                                disabled={!values.email}
                                onClick={() => {
                                  if (isValid) {
                                    dispatch(
                                      findUserStart({
                                        email: values.email,
                                        for_create_contact: 1,
                                        user_type: values.recipient_type,
                                      })
                                    );
                                  } else {
                                    getErrorNotificationMessage(
                                      "Invalid Email"
                                    );
                                  }
                                }}
                              >
                                {findUser.buttonDisable ? (
                                  <ButtonLoader />
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#000"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-check"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    />
                                    <path d="M5 12l5 5l10 -10" />
                                  </svg>
                                )}
                              </Button>
                            </InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="errorMsg"
                          />
                        </div>
                        <Form.Group className="mb-3">
                          <Form.Label className="default-form-label">
                            Full Name
                          </Form.Label>
                          <Field
                            className="form-control"
                            name="name"
                            type="text"
                            placeholder="Name"
                            aria-label="text"
                            aria-describedby="text-addon"
                            disabled
                          />
                          <ErrorMessage
                            component={"div"}
                            name="name"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3 register-phone-input">
                          <Form.Label className="default-form-label">
                            Phone Number
                          </Form.Label>
                          {/* <PhoneInput
                            name="mobile"
                            defaultCountry={"AE"}
                            placeholder={t("mobile.placeholder")}
                            onChange={(value) => setFieldValue("mobile", value)}
                            onBlur={() => formRef.current.submitForm()}
                            international
                            disabled
                            value={values.mobile}
                            countries={filteredCountries}
                            className={`${
                              touched.mobile && errors.mobile ? "is-invalid" : ""
                            }`}
                          /> */}
                          <Field
                            className="form-control"
                            name="mobile"
                            type="text"
                            placeholder="Mobile"
                            aria-label="text"
                            aria-describedby="text-addon"
                            disabled
                          />
                          <ErrorMessage
                            component={"div"}
                            name="mobile"
                            className="errorMsg"
                          />
                        </Form.Group>
                      </Col>
                    {/* )} */}
                  </Row>
                </div>
                <div className="payment-form-footer">
                  <Button
                    className="efi-overlay-btn w-100"
                    onClick={() => props.onHide()}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="efi-primary-btn w-100"
                    disabled={
                      contactsStore.buttonDisable ||
                      (profile.data.user_type != 2 && !userFound)
                    }
                    type="submit"
                  >
                    {contactsStore.buttonDisable ? <ButtonLoader /> : "Save"}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContactCreateModal;
